/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/styles';

import { useRouter } from 'src/routes/hooks';
import { formatDate } from 'src/utils/date-utils';
import formatINR from 'src/utils/format-inr';

import { transactionType } from 'src/datas';
import { ColumnDefinition } from 'src/interfaces';
import { SalesStatus } from 'src/datas/sales-status';

import { AppDispatch, RootState } from 'src/store/store';
import { fetchNegotiationApprovals, resetRecords } from 'src/store/slices/sales-slices';

import FilterComponent from 'src/layouts/common/filter-search';
import { MRT_ColumnDef } from 'material-react-table';
import ReactTable from 'src/components/table/material-table';



const NegotiationApprovalTable: React.FC<{ status: 'PENDING' | 'ALL' | 'APPROVED' | 'REJECTED' | '' }> = ({
  status,
}) => {
  const router = useRouter();
  const classes = useStyles();

  // State management
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedTransactionType, setSelectedTransactionType] = useState<string>('');
  const [searchInitiated, setSearchInitiated] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { records, totalRecords, loading } = useSelector((store: RootState) => store.sales);

  // Navigate to transaction
  const navigateToTransaction = (estimationId: string, transactionId: string) => {
    router.push(`estimations/${estimationId}/transactions/${transactionId}`);
  };

  // Table column definitions
  const columns: MRT_ColumnDef<ColumnDefinition>[] = [
    { accessorKey: 'trans_id', header: 'Transaction ID', accessorFn: (row: any) => row?.code },
    { accessorKey: 'customer_id', header: 'Customer ID', accessorFn: (row: any) => row?.customer?.id },
    {
      accessorKey: 'customer_name',
      header: 'Customer',
      accessorFn: (row: any) => (
        <p className={classes.name}>
          {row?.customer?.first_name} {row?.customer?.last_name}
        </p>
      ),
    },
    { accessorKey: 'service_charge', header: 'Service Charge', accessorFn: (row: any) => `${row?.estimation?.service_charge}%` },
    { accessorKey: 'final_amount', header: 'Final Amount', accessorFn: (row: any) => `₹${formatINR(row?.estimation?.final_amount)}` },
    { accessorKey: 'transaction_type', header: 'Transaction Type', accessorFn: (row: any) => transactionType[row?.transaction_type] },
    { accessorKey: 'date', header: 'Transaction Date', accessorFn: (row: any) => formatDate(row?.created_at) },
    {
      accessorKey: 'status',
      header: 'Status',
      accessorFn: (row: any) => (
        <Card sx={SalesStatus[row?.estimation?.negotiation_approval]?.style}>
          {SalesStatus[row?.estimation?.negotiation_approval]?.label}
        </Card>
      ),
    },
    {
      accessorKey: 'action',
      header: 'Actions',
      enableSorting: false,
      accessorFn: (row: any) => (
        <Button
          onClick={() => navigateToTransaction(row?.estimation?.id, row?.id)}
          variant="outlined"
        >
          View
        </Button>
      ),
    },
  ];

  // Fetch transactions
  const fetchTransactions = useCallback(async () => {
    try {
      const resp = await dispatch(
        fetchNegotiationApprovals({
          status,
          pageSize,
          page: page + 1,
          searchText: searchQuery,
          startDate,
          endDate,
          transactionType: selectedTransactionType,
        })
      );
      return resp;
    } catch (error) {
      console.log(error);
      return null;
    }
  }, [dispatch, status, page, pageSize, searchQuery, startDate, endDate, selectedTransactionType]);

  useEffect(() => {
    if (searchInitiated) {
      fetchTransactions();
    }
    return () => {
      dispatch(resetRecords());
    };
  }, [fetchTransactions, searchInitiated]);

  const handleDateChange = (startDateVal: string, endDateVal: string) => {
    setStartDate(startDateVal);
    setEndDate(endDateVal);
    setSearchInitiated(true); // Set search initiated to true
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setSearchInitiated(true); // Set search initiated to true
  };

  const handleTransactionTypeChange = (type: string) => {
    setSelectedTransactionType(type);
    setSearchInitiated(true); // Set search initiated to true
  };

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setPageSize]
  );

  return (
    <div>
      <FilterComponent
        onDateChange={handleDateChange}
        onSearch={handleSearch}
        onTransactionTypeChange={handleTransactionTypeChange}
        searchResultCount={searchInitiated ? totalRecords : 0} // Pass totalRecords when search initiated
      />
      <ReactTable
        enableSorting={false}
        enableStickyHeader
        loading={loading}
        headers={columns}
        rows={records}
        totalRecords={totalRecords}
        page={page}
        pageSize={pageSize}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Divider />
    </div>
  );
};
const useStyles = makeStyles({
  name: {
    fontWeight: 600,
  },
});
export default NegotiationApprovalTable;
