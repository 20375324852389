// @ts-nocheck
/* eslint-disable */

import * as XLSX from 'xlsx';
import { jsPDF as JsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';

const styles = {
  pdf: {
    title: {
      fontSize: 16,
      textColor: [40, 40, 40], // Dark grey
      align: 'center',
    },
    groupHeader: {
      fillColor: [30, 144, 255], // Blue
      textColor: [255, 255, 255], // White
      fontStyle: 'bold',
      halign: 'center',
    },
    emptyGroupHeader: {
      fillColor: [200, 200, 200], // Light grey
      textColor: [0, 0, 0], // Black
    },
    columnHeader: {
      fillColor: [0, 46, 167], // to #002EA7
      textColor: [255, 255, 255], // White
      fontStyle: 'bold',
      fontSize: 10,
      halign: 'center',
    },
    body: {
      font: 'helvetica',
      fontSize: 8,
      cellPadding: 3,
      fillColor: [255, 255, 255], // White for body rows
      textColor: [0, 0, 0], // Black for text
    },
    alternateRow: {
      fillColor: [245, 245, 245], // Light grey for alternate rows
    },
  },
  excel: {
    groupHeaderFillColor: '#ADD8E6', // Light Blue for Excel Group Headers
  },
};

/**
 * Utility function to export table data as an image (PNG).
 * @param fields - Array of column definitions with headers.
 * @param records - Array of row data that matches column accessors.
 * @param fileName - The name of the generated image file.
 */

export const exportToImage = async (fields, records, fileName) => {
  // Filter out the "Actions" header field
  const filteredFields = fields.filter((field) => field.header !== 'Actions');

  // Create container for the table
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.top = '-9999px';
  container.style.width = '800px'; // Limit width
  container.style.fontFamily = 'Arial, sans-serif';
  container.style.fontSize = '12px'; // Reduce font size

  // Create title
  const title = document.createElement('h1');
  title.textContent = fileName; // Dynamic title
  title.style.textAlign = 'center';
  title.style.fontSize = '16px';
  title.style.color = 'rgb(40, 40, 40)';
  container.appendChild(title);

  // Create table
  const table = document.createElement('table');
  table.style.borderCollapse = 'collapse';
  table.style.width = '100%';

  // Header row
  const thead = document.createElement('thead');
  const headerRow = document.createElement('tr');
  filteredFields.forEach((field) => {
    const th = document.createElement('th');
    th.textContent = field.header;
    th.style.backgroundColor = 'rgb(0, 46, 167)';
    th.style.color = 'white';
    th.style.padding = '8px';
    th.style.border = '1px solid #ddd';
    headerRow.appendChild(th);
  });
  thead.appendChild(headerRow);
  table.appendChild(thead);

  // Body rows
  const tbody = document.createElement('tbody');
  records.forEach((record, rowIndex) => {
    const tr = document.createElement('tr');
    filteredFields.forEach((field) => {
      const td = document.createElement('td');
      td.textContent = record[field.key || field.accessorKey] || '-';
      td.style.padding = '8px';
      td.style.border = '1px solid #ddd';
      td.style.backgroundColor = rowIndex % 2 === 0 ? '#f5f5f5' : 'white';
      tr.appendChild(td);
    });
    tbody.appendChild(tr);
  });

  // Totals row
  const totalsRow = document.createElement('tr');
  filteredFields.forEach((field, index) => {
    const td = document.createElement('td');
    if (index === 0) {
      td.textContent = 'Total';
    } else if (field.accessorKey && typeof records[0]?.[field.accessorKey] === 'number') {
      const total = records.reduce((sum, row) => sum + (row[field.accessorKey] || 0), 0).toFixed(2);
      td.textContent = total;
    } else {
      td.textContent = ''; // Empty cell for non-numeric fields
    }
    td.style.padding = '8px';
    td.style.border = '1px solid #ddd';
    td.style.fontWeight = 'bold';
    td.style.backgroundColor = '#e0e0e0'; // Light grey for totals row
    totalsRow.appendChild(td);
  });
  tbody.appendChild(totalsRow);

  table.appendChild(tbody);
  container.appendChild(table);
  document.body.appendChild(container);

  // Render image
  const canvas = await html2canvas(container, { scale: 1, useCORS: true });
  const imageURL = canvas.toDataURL('image/png');

  // Download image
  const link = document.createElement('a');
  link.href = imageURL;
  link.download = fileName.endsWith('.png') ? fileName : `${fileName}.png`;
  link.click();

  // Clean up
  document.body.removeChild(container);
};

/**
 * Utility function to export table data to PDF.
 * @param columns - Array of column definitions with headers.
 * @param records - Array of row data that matches column accessors.
 * @param fileName - The name of the generated PDF file.
 */
export const exportToPDF = (fields: any[], records: any[], fileName: string) => {
  const orientation = 'landscape';
  const doc = new JsPDF({ orientation });

  // Prepare headers
  const tableHeaders = fields.filter((field) => field.header !== 'Actions').map((field) => field.header);

  // Prepare table data
  const tableData = records.map((row) =>
    fields.map((field) => (row[field.key || field.accessorKey] != null ? row[field.key || field.accessorKey] : '-'))
  );

  // Compute totals for applicable columns
  const footerTotals = fields.map((field, index) => {
    if (index === 0) return 'Total';
    if (field.accessorKey && typeof records[0]?.[field.accessorKey] === 'number') {
      return records.reduce((sum, row) => sum + (row[field.accessorKey] || 0), 0).toFixed(2);
    }
    return '';
  });

  // Add footer row for totals
  tableData.push(footerTotals);

  // Add the table to the PDF document
  autoTable(doc, {
    head: [tableHeaders],
    body: tableData,
    theme: 'grid',
    headStyles: styles.pdf.columnHeader,
    alternateRowStyles: styles.pdf.alternateRow,
    styles: styles.pdf.body,
    startY: 20,
    margin: { top: 10, left: 10, right: 10 },
  });

  // Add a title
  doc.setFontSize(styles.pdf.title.fontSize);
  doc.setTextColor(...styles.pdf.title.textColor);
  doc.text('Report', doc.internal.pageSize.getWidth() / 2, 10, { align: styles.pdf.title.align });

  // Save the PDF file
  doc.save(fileName);
};

/**
 * Utility function to export table data to Excel.
 * @param columns - Array of column definitions with headers.
 * @param records - Array of row data that matches column accessors.
 * @param fileName - The name of the generated Excel file.
 */
export const exportToExcel = (fields: any[], records: any[], fileName: string) => {
  // Filter out the "Actions" header
  const filteredFields = fields.filter((field) => field.header !== 'Actions');

  // Prepare the headers
  const fieldHeaders = filteredFields.map((field) => field.header);

  // Prepare the data rows
  const tableData = records.map((row) =>
    filteredFields.map((field) => row[field.key || field.accessorKey] || '')
  );

  // Compute totals for applicable columns
  const footerTotals = filteredFields.map((field, index) => {
    if (index === 0) return 'Total';
    if (field.accessorKey && typeof records[0]?.[field.accessorKey] === 'number') {
      return records.reduce((sum, row) => sum + (row[field.accessorKey] || 0), 0).toFixed(2);
    }
    return '';
  });

  // Combine headers, data rows, and totals
  const completeData = [fieldHeaders, ...tableData, footerTotals];

  // Convert to a worksheet
  const ws = XLSX.utils.aoa_to_sheet(completeData);

  // Create a workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Audit Report');

  // Ensuring the file name includes the `.xlsx` extension
  const validFileName = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`;

  // Trigger the Excel file download
  XLSX.writeFile(wb, validFileName);
};