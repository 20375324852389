// @ts-nocheck
/* eslint-disable */

import {
  Box,
  Container,
  Grid,
  MenuItem,
  Stack,
  TableCellProps,
  Typography
} from '@mui/material';
import {
  type MRT_ColumnDef
} from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionDetailsById } from 'src/apis/transactions';
import { RHFTextField } from 'src/components/hook-form';
import { AppDispatch, RootState } from 'src/store/store';
import * as Yup from 'yup';

// component
import { useSettingsContext } from 'src/components/settings';

// Utils
import { yupResolver } from '@hookform/resolvers/yup';
import { Estimation, Ornament } from 'src/interfaces';
import { resetRecords, setCurrentTransactions } from 'src/store/slices/sales-slices';
import {
  calculateGoldAmountByRange,
  getGoldRatesByRange,
  getPurityRange,
  restrictToTwoDecimals,
} from 'src/utils/purity-calculation';

import { LoadingButton } from '@mui/lab';
import CustomBreadcrumbs from 'src/components/custom-breadcrumb/custom-breadcrumb';
import { useParams, useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

import SalesHeadApprovalModal from '../../components/modals/sales/sales-head-modal';
import ApprovalModal from '../../components/modals/sales/servicecharge-approval-modal';
import RejectionModal from '../../components/modals/sales/servicecharge-reject-modal';

import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { getEstimationById } from 'src/apis/estimations';
import AlertBanner from 'src/components/alert-banner';
import GoldRateDiffChecker from 'src/components/goldrate-tooltip';
import FileUploader from 'src/components/image-uploader';
import Overlay from 'src/components/overlay';
import PageTitle from 'src/components/page-title';
import TotalCount from 'src/components/table-count';
import { goldKarats } from 'src/datas/gold-karats';
import useScrollToError from 'src/hooks/use-scroll-error';
import { setAlert } from 'src/store/slices/alerts-slice';
import {
  serviceChargeApprovalSalesMessage
} from 'src/utils/alert-status-handlers';
import formatINR from 'src/utils/format-inr';
import { ServiceChargeSchema } from 'src/sections/components/schema/service-charge-schema';
import { MaterialTable } from 'src/components/material-table';
import rolePermissions from 'src/layouts/dashboard/role_permissions.json';

// Render Transaction details
const RenderCustomerDetails = () => (
  <>
    <Typography mt={2} variant="subtitle2">
      Customer Details
    </Typography>
    <Grid container mb={3} pt={2} spacing={3}>
      <Grid item md={4}>
        <RHFTextField name="name" disabled label="Customer Name" />
      </Grid>
      <Grid item md={4}>
        <RHFTextField name="mobile" disabled label="Customer Mobile Number" />
      </Grid>
      <Grid item md={4}>
        <RHFTextField name="customer_id" disabled label="Customer ID" />
      </Grid>
    </Grid>
  </>
);

const RenderGoldRates = () => {
  const { currentTransaction } = useSelector((store: RootState) => store.sales);
  const estimation: Estimation = currentTransaction?.estimation;

  return (
    <>
      <Typography variant="subtitle2">Gold Rates</Typography>
      <Grid container mb={5} pt={2} spacing={3}>
        <Grid item md={4}>
          <RHFTextField
            name="margin_24k"
            showRupeeAdornment={true}
            formatRupee={true}
            InputLabelProps={{ shrink: true }}
            type="number"
            label="24k Purity Gold Price"
          />
          <Box mt={1}>
            <GoldRateDiffChecker
              oldRate={estimation?.margin_24k}
              newRate={estimation?.updated_margin_24k}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <RHFTextField
            name="margin_22k"
            showRupeeAdornment={true}
            formatRupee={true}
            InputLabelProps={{ shrink: true }}
            type="number"
            label="22k Purity Gold Price"
          />
          <Box mt={1}>
            <GoldRateDiffChecker
              oldRate={estimation?.margin_22k}
              newRate={estimation?.updated_margin_22k}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <RHFTextField
            name="rate_17k_21k"
            showRupeeAdornment={true}
            formatRupee={true}
            InputLabelProps={{ shrink: true }}
            type="number"
            label="21k-17k Purity Gold Price"
          />
          <Box mt={1}>
            <GoldRateDiffChecker
              oldRate={estimation?.rate_17k_21k}
              newRate={estimation?.updated_rate_17k_21k}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <RHFTextField
            name="rate_14k_17k"
            showRupeeAdornment={true}
            formatRupee={true}
            InputLabelProps={{ shrink: true }}
            type="number"
            label="17k-14k Purity Gold Rate"
          />
          <Box mt={1}>
            <GoldRateDiffChecker
              oldRate={estimation?.rate_14k_17k}
              newRate={estimation?.updated_rate_14k_17k}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const RenderHeader = ({
  isSubmitting,
  handleSubmit,
  setOpenApprovalModal,
  setRejectModal,
  originalGoldRate,
  setSalesApprovalModal,
}: {
  isSubmitting: boolean;
  handleSubmit: any;
  setOpenApprovalModal: (val: boolean) => void;
  setRejectModal: (val: boolean) => void;
  originalGoldRate: any;
  setSalesApprovalModal: (val: boolean) => void;
}) => {
  const { currentTransaction } = useSelector((store: RootState) => store.sales);
  const { title } = useSelector((store: RootState) => store.alerts);
  const disableAction = ['Approved', 'Rejected'];

  const { user } = useSelector((store: RootState) => store.user);
  const userRole = user?.role ?? ''; // Get the user's role
  const hasWriteAccess = rolePermissions.roles.SALES.write.includes(userRole); // Check write access

  const rejectHandler = () => {
    setRejectModal(true);
  };

  const approveHandler = (e: Estimation) => {
    // check if the margin diff is more than 25 rupees
    const { margin_24k, margin_22k, rate_17k_21k, rate_14k_17k } = originalGoldRate;
    // Convert e.margin_24k to a number and default to 0 if undefined or null
    const e_margin_24k = Number(e.margin_24k ?? 0);
    // Access e['22k'] safely and convert to a number
    const e_22k = Number(e.margin_22k ?? 0);
    // Access e['21k'] safely and convert to a number
    const e_21k = Number(e.rate_17k_21k ?? 0) * 1;
    // Access e['17k'] safely and convert to a number
    const e_17k = Number(e.rate_14k_17k ?? 0);
    setOpenApprovalModal(true);
    // if (
    //   margin_24k - e_margin_24k > 25 ||
    //   margin_24k - e_margin_24k < -25 ||
    //   margin_22k - e_22k > 25 ||
    //   margin_22k - e_22k < -25 ||
    //   rate_17k_21k - e_21k > 25 ||
    //   rate_17k_21k - e_21k < -25 ||
    //   rate_14k_17k - e_17k > 25 ||
    //   rate_14k_17k - e_17k < -25
    // ) {
    //   setSalesApprovalModal(true);
    //   // Perform actions if conditions are met (optional)
    // } else {
    //   setOpenApprovalModal(true);
    // }
  };

  return (
    <>
      <PageTitle title={`Estimation : ${currentTransaction?.customer?.first_name}`} />
      <CustomBreadcrumbs
        heading={
          currentTransaction
            ? `Estimation of ${currentTransaction?.customer?.first_name} ${currentTransaction?.customer?.last_name}`
            : 'Estimation'
        }
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          { name: 'Service Charge Approvals', href: paths.dashboard.serviceChargeApprovals },
          { name: 'Estimation' },
        ]}
        fixed={true}
        action={
          hasWriteAccess && !disableAction.includes(title) ? ( // Conditionally render buttons
            <Stack direction={'row'} spacing={2}>
              <LoadingButton
                variant="contained"
                color="error"
                onClick={handleSubmit(rejectHandler)}
              >
                Reject
              </LoadingButton>
              <LoadingButton
                onClick={handleSubmit(approveHandler)}
                variant="contained"
              >
                Approve
              </LoadingButton>
            </Stack>
          ) : null
        }
        sx={{
          mb: { xs: 3, md: 1 },
        }}
      />
    </>
  );
};

// Render service charges and amounts
const RenderServiceAmounts = () => {
  const { setValue, register } = useFormContext();
  const ornaments = useWatch({ name: ['ornaments', 'service_charge'] });

  useEffect(() => {
    let sum = 0;
    let serviceCharge = ornaments[1];
    let serviceChargeAmount = 0;
    let finalAmount = 0;

    if (ornaments[0].length) {
      ornaments[0].forEach((ornament: Ornament) => {
        sum = sum + (ornament?.amount || 0) * 1;
      });
    }
    serviceChargeAmount = sum * (Number(serviceCharge) / 100);
    finalAmount = sum - serviceChargeAmount;
    setValue('final_amount', finalAmount.toFixed(2));
    setValue('service_charge_amount', serviceChargeAmount.toFixed(2));
  }, [ornaments]);

  return (
    <>
      <Grid container mb={5} pt={2} spacing={3}>
        <Grid item md={4}>
          <RHFTextField
            name="service_charge"
            inputProps={{ type: 'number', onInput: restrictToTwoDecimals }}
            label="Service Charge (In Percentage)"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={4}>
          <RHFTextField
            name="service_charge_amount"
            formatRupee={true}
            disabled
            label="Service Charge Amount"
          />
        </Grid>
        <Grid item md={4}>
          <RHFTextField name="final_amount" disabled label="Final Amount" formatRupee={true} />
        </Grid>
        <Grid item md={4}>
          <RHFTextField
            disabled
            name="customer_expected_amount"
            inputProps={{ onInput: restrictToTwoDecimals }}
            formatRupee={true}
            label="Customer Expected Amount (Optional)"
            type="number"
          />
        </Grid>
      </Grid>
    </>
  );
};

// Main render function
const ServiceChargeForm = () => {
  const { estimation_id, transaction_id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const router = useRouter();
  //styles
  const classes = styles();

  // store
  const { currentTransaction } = useSelector((store: RootState) => store.sales);
  const dispatch = useDispatch<AppDispatch>();
  const { title } = useSelector((store: RootState) => store.alerts);
  const disableAction = ['Approved', 'Rejected'];

  // states
  const [fetchingEstimation, setFetchingEstimation] = useState(false);
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const [openRejectModal, setRejectModal] = useState(false);
  const [salesApprovalModal, setSalesApprovalModal] = useState(false);
  const [originalGoldRate, setOriginalGoldrate] = useState({});

  const settings = useSettingsContext();

  // fetch estimation details
  const fetchEstimation = async (estimationId: any) => {
    try {
      setFetchingEstimation(true);
      const estimation = await getEstimationById(estimationId);
      remove();
      setFormValues(estimation);
      showAlerts(estimation);
    } catch (error) {
      console.log(error);
    }
    setFetchingEstimation(false);
  };

  // alert handlers
  const showAlerts = (estimation: Estimation) => {
    const { title, type, message, status } = serviceChargeApprovalSalesMessage(estimation);
    dispatch(setAlert({ title, type, message, status }));
  };

  // fetch estimation details
  const fetchTransactionDetails = async () => {
    try {
      const transaction = await getTransactionDetailsById(transaction_id);
      dispatch(setCurrentTransactions(transaction));
      const { customer } = transaction;
      if (customer) {
        setValue('customer_id', customer.id);
        setValue('name', customer.first_name + ' ' + customer.last_name);
        setValue('mobile', customer.mobile);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTransactionDetails();
    fetchEstimation(estimation_id);
    return () => {
      dispatch(resetRecords());
    };
  }, [router]);

  const setFormValues = React.useCallback((data: Estimation) => {
    setOriginalGoldrate(data);

    let margin24k = data['updated_margin_24k'] ? data['updated_margin_24k'] : data['margin_24k'];

    let margin22k = data['updated_margin_22k'] ? data['updated_margin_22k'] : data['margin_22k'];

    let _17kto21k = data['updated_rate_17k_21k']
      ? data['updated_rate_17k_21k']
      : data['rate_17k_21k'];

    let _14kto17k = data['updated_rate_14k_17k']
      ? data['updated_rate_14k_17k']
      : data['rate_14k_17k'];

    setValue('margin_24k', margin24k + '');
    setValue('margin_22k', margin22k + '');
    setValue('rate_17k_21k', _17kto21k + '');
    setValue('rate_14k_17k', _14kto17k + '');

    setValue('customer_expected_amount', data.customer_expected_amount);
    setValue('final_amount', data.final_amount);
    setValue('service_charge_amount', data.service_charge_amount);
    setValue('service_charge', data.service_charge);
    setValue('customer_expected_amount', data.customer_expected_amount);

    data.ornaments.forEach((item, index) => {
      insert(index, {
        id: item.id,
        ornament_images: item.ornament_images,
        ornament_name: item.name || '',
        gross_weight: item.gross_weight,
        stone_weight: item.stone_weight,
        wastage: item.wastage,
        net_weight: item.net_weight,
        purity: item.purity,
        gold_rate: item.gold_rate,
        branch_purity: item.branch_purity,
        purity_value: item.purity_value,
        amount: item.amount,
      });
    });
  }, []);

  // Form init values
  let ornamentInitialValues: Ornament = {
    s_no: 0,
    ornament_images: [],
    ornament_name: '',
    gross_weight: null,
    stone_weight: 0,
    wastage: 0,
    net_weight: 0,
    purity: '',
    branch_purity: '',
    gold_rate: 0,
    amount: 0,
    purity_value: 0
  };

  // form methods
  const methods = useForm({
    defaultValues: {
      ornaments: [ornamentInitialValues],
      margin_24k: '0',
      margin_22k: '0',
      rate_17k_21k: '0',
      rate_14k_17k: '0',
      name: '',
      mobile: '',
      customer_id: '',
      expected_amount: 0,
      customer_expected_amount: 0,
      final_amount: 'N/A',
      service_charge_amount: 0,
      service_charge: 3,
      total_gross: 0,
    },
    resolver: yupResolver(ServiceChargeSchema) as any,
  });

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
  } = methods;

  const { fields, append, prepend, remove, insert } = useFieldArray({
    control: control,
    name: 'ornaments',
  });

  const headerStyle: TableCellProps = {
    align: 'center',
  };

  useScrollToError(errors);

  // --------------------- Estimation Table -----------------------

  // table columns
  const columns = useMemo<MRT_ColumnDef<Ornament>[]>(
    () => [
      {
        accessorKey: 's_no',
        header: 'S.no',
        size: 90,
        muiTableHeadCellProps: headerStyle,
        Footer: () => {
          const ornaments = watch('ornaments');
          return <TotalCount>Total: {ornaments.length}</TotalCount>;
        },
        Cell: ({ row: { index, original } }) => (
          <RHFTextField
            disabled
            key={original.id}
            sx={{ outlineColor: 'red' }}
            name={`ornaments.${index}.s_no`}
            value={`${index + 1}.`}
          />
        ),
      },
      // column definitions...
      {
        accessorKey: 'ornament_images',
        header: 'Image',
        size: 90,
        muiTableHeadCellProps: headerStyle,
        Cell: ({ row: { index, original } }) => {
          // ornament image
          const ornamentImage = watch(`ornaments.${index}.ornament_images`);

          return (
            <FileUploader
              editable={false}
              name={`ornaments.${index}.ornament_images`}
              key={original.id}
              defaultValue={ornamentImage}
              onChange={(value: string) => {
                setValue(`ornaments.${index}.ornament_images`, value);
              }}
            />
          );
        },
      },
      {
        accessorKey: 'ornaments',
        header: 'Ornaments',
        muiTableHeadCellProps: headerStyle,
        Cell: ({ row: { index, original } }) => (
          <RHFTextField key={original.id} disabled name={`ornaments.${index}.ornament_name`} />
        ),
      },

      {
        accessorKey: 'purity_value',
        header: 'Purity %',
        muiTableHeadCellProps: headerStyle,
        headerStyle: {
          width: 10,
        },
        size: 100,
        Cell: ({ row: { index, original } }) => {
          return (
            <RHFTextField disabled key={original.id} name={`ornaments.${index}.purity_value`} />
          );
        },
      },

      {
        accessorKey: 'gross_weight',
        header: 'Gross Weight (gms)',
        muiTableHeadCellProps: headerStyle,
        size: 120,
        Footer: () => {
          const ornamentChanges = watch('ornaments');
          return (
            <TotalCount>
              {_.sum(_.map(ornamentChanges, (d) => Number(d.gross_weight))).toFixed(2)} gms
            </TotalCount>
          );
        },
        Cell: ({ row: { index, original } }) => (
          <RHFTextField
            disabled
            key={original.id}
            name={`ornaments.${index}.gross_weight`}
            inputProps={{
              type: 'number',
            }}
          />
        ),
      },
      {
        accessorKey: 'stone',
        header: 'Stone Weight (gms)',
        size: 120,
        muiTableHeadCellProps: headerStyle,
        Footer: () => {
          const ornamentChanges = watch('ornaments');
          return (
            <TotalCount>
              {_.sum(_.map(ornamentChanges, (d) => Number(d.stone_weight))).toFixed(2)} gms
            </TotalCount>
          );
        },
        Cell: ({ row: { index, original } }) => (
          <RHFTextField
            disabled
            inputProps={{
              type: 'number',
            }}
            key={original.id}
            name={`ornaments.${index}.stone_weight`}
          />
        ),
      },
      {
        accessorKey: 'wastage',
        header: 'Wastage (gms)',
        size: 120,
        muiTableHeadCellProps: headerStyle,
        Cell: ({ row: { index, original } }) => (
          <RHFTextField
            key={original.id}
            name={`ornaments.${index}.wastage`}
            inputProps={{ type: 'number', onInput: restrictToTwoDecimals }}
          />
        ),
        Footer: () => {
          const ornamentChanges = watch('ornaments');
          return (
            <TotalCount>
              {_.sum(_.map(ornamentChanges, (d) => Number(d.wastage))).toFixed(2)} gms
            </TotalCount>
          );
        },
      },

      {
        accessorKey: 'net_weight',
        header: 'Net Weight (gms)',
        muiTableHeadCellProps: headerStyle,
        size: 120,

        Footer: () => {
          const ornamentChanges = watch('ornaments');
          return (
            <TotalCount>
              {_.sum(_.map(ornamentChanges, (d) => Number(d.net_weight))).toFixed(2)} gms
            </TotalCount>
          );
        },
        Cell: ({ row: { index, original } }) => {
          // watch the gross weight stone weight and wastage changes

          const grossWeight = watch(`ornaments.${index}.gross_weight`);
          const stoneWeight = watch(`ornaments.${index}.stone_weight`);
          const wastage = watch(`ornaments.${index}.wastage`);

          const calculateNetWeight = useMemo(() => {
            return ((grossWeight ?? 0) - stoneWeight - (wastage ?? 0)).toFixed(2);
          }, [grossWeight, stoneWeight, wastage]);

          useEffect(() => {
            setValue(`ornaments.${index}.net_weight`, calculateNetWeight);
          }, [calculateNetWeight]);

          return (
            <RHFTextField
              key={original.id}
              disabled
              name={`ornaments.${index}.net_weight`}
              inputProps={{
                type: 'number',
              }}
            />
          );
        },
      },

      // {
      //   accessorKey: 'purity',
      //   header: 'Purity',
      //   size: 160,
      //   muiTableHeadCellProps: headerStyle,
      //   Cell: ({ row: { index, original } }) => (
      //     <RHFTextField key={original.id} name={`ornaments.${index}.purity`} disabled select>
      //       {goldKarats.map((item) => (
      //         <MenuItem value={item.value}>{item.label}</MenuItem>
      //       ))}
      //     </RHFTextField>
      //   ),
      // },
      {
        accessorKey: 'branch_purity',
        header: 'Branch Purity',
        size: 170,
        muiTableHeadCellProps: headerStyle,
        Cell: ({ row: { index, original } }) => {
          // Function to handle change in dropdown selection
          const handleChange = (selectedRange: number) => {
            const _24k = watch('margin_24k');
            const _22k = watch('margin_22k');
            const _21k_17k = watch('rate_17k_21k');
            const _17k_14k = watch('rate_14k_17k');
            const selectedRate: any = getGoldRatesByRange(
              selectedRange,
              _24k,
              _22k,
              _21k_17k,
              _17k_14k
            );
            setValue(`ornaments.${index}.branch_purity`, selectedRange + '');
            setValue(`ornaments.${index}.gold_rate`, selectedRate);
          };

          return (
            <RHFTextField
              disabled
              key={original.id}
              name={`ornaments.${index}.branch_purity`}
              onChange={(e) => {
                handleChange(Number(e.target.value));
              }}
              select
            >
              {goldKarats.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </RHFTextField>
          );
        },
      },
      {
        accessorKey: 'gold_rate',
        header: 'Gold Rate',
        muiTableHeadCellProps: headerStyle,
        size: 160,
        Cell: ({ row: { index, original } }) => {
          const goldRates = watch(['margin_24k', 'margin_22k', 'rate_17k_21k', 'rate_14k_17k']);
          const calculateAmount = useMemo(() => {
            const branchPurity = watch(`ornaments.${index}.branch_purity`);

            // Determine the associated gold rate based on branchPurity
            const range = getPurityRange(Number(branchPurity));
            let associatedGoldRate;
            switch (range) {
              case '24k':
                associatedGoldRate = goldRates[0];
                break;
              case '22k':
                associatedGoldRate = goldRates[1];
                break;
              case '21k-17k':
                associatedGoldRate = goldRates[2];
                break;
              case '17k-14k':
                associatedGoldRate = goldRates[3];
                break;
              default:
                associatedGoldRate = 0; // Default value if branchPurity doesn't match any case
                break;
            }
            return associatedGoldRate;
          }, [goldRates]);

          // Update ornaments with the calculated gold_rate
          useEffect(() => {
            setValue(`ornaments.${index}.gold_rate`, Number(calculateAmount));
          }, [calculateAmount]);

          return (
            <RHFTextField
              disabled
              key={original.id}
              showRupeeAdornment={true}
              inputProps={{ onInput: restrictToTwoDecimals }}
              formatRupee={true}
              name={`ornaments.${index}.gold_rate`}
            />
          );
        },
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        size: 140,
        muiTableHeadCellProps: headerStyle,
        Footer: () => {
          const ornamentChanges = watch('ornaments');
          return (
            <TotalCount>
              ₹ {formatINR(_.sum(_.map(ornamentChanges, (d) => Number(d.amount))).toFixed(2))}
            </TotalCount>
          );
        },
        Cell: ({ row: { index, original } }) => {
          const netWeight = watch(`ornaments.${index}.net_weight`);
          const branchPurity = watch(`ornaments.${index}.branch_purity`);
          const goldRates = watch(`ornaments.${index}.gold_rate`);
          const purityValue = watch(`ornaments.${index}.purity_value`);

          const calculateAmount = useMemo(() => {
            return calculateGoldAmountByRange(
              Number(branchPurity),
              purityValue,
              goldRates ?? 0,
              netWeight
            );
          }, [netWeight, branchPurity, goldRates, purityValue]);

          useEffect(() => {
            setValue(`ornaments.${index}.amount`, calculateAmount as any);
          }, [calculateAmount]);

          return (
            <RHFTextField
              disabled
              key={original.id}
              formatRupee={true}
              inputProps={{ onInput: restrictToTwoDecimals }}
              name={`ornaments.${index}.amount`}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <RenderHeader
        setRejectModal={setRejectModal}
        setOpenApprovalModal={setOpenApprovalModal}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        originalGoldRate={originalGoldRate}
        setSalesApprovalModal={setSalesApprovalModal}
      />
      <AlertBanner />
      <Container className={classes.formContainer} maxWidth={settings.themeStretch ? false : 'xl'}>
        <FormProvider {...methods}>
          <form id="valuation_update">
            <RenderCustomerDetails />
            <RenderGoldRates />
            <Typography variant="subtitle2" mb={1}>
              List of Ornaments
            </Typography>
            <MaterialTable fields={fields} columns={columns} />
            <Box my={2} />
            <RenderServiceAmounts />
          </form>
          <RejectionModal
            open={openRejectModal}
            onClose={() => setRejectModal(false)}
            originalGoldRate={originalGoldRate}
          />
          <ApprovalModal
            originalGoldRate={originalGoldRate}
            open={openApprovalModal}
            onClose={() => setOpenApprovalModal(false)}
          />
          <SalesHeadApprovalModal
            originalGoldRate={originalGoldRate}
            open={salesApprovalModal}
            onClose={() => setSalesApprovalModal(false)}
          />
        </FormProvider>
        {/* <Toaster /> */}
        <Overlay showOverlay={disableAction.includes(title)} />
      </Container>
    </>
  );
};

const styles = makeStyles({
  formContainer: {
    backgroundColor: '#fff',
    padding: 18,
    position: 'relative',
    marginTop: 13,
  },
});

export default ServiceChargeForm;
