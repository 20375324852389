import dayjs from 'dayjs';

import { BidderSaleRecord, AfterMeltingRecord, BeforeMeltingRecord } from 'src/interfaces';

import API from '..';
import { markets } from '../entpoints';

// get latest batch
export const getLatestBatch = async (date: string, state: string) => {
  try {
    const response = await API.get(`${markets.getLatestBatch}?date=${date}&state=${state}`);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getBids = async (date: string) => {
  try {
    const response = await API.get(`${markets.getBids}?date=${date}`);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getBatchesOption = async (date: string) => {
  try {
    const response = await API.get(`${markets.getBatchesOptions}?date=${date}`);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAllBatches = async (
  state: string,
  pageSize: number,
  page: number,
  startDate: string,
  endDate: string,
  searchText: string
) => {
  try {
    const response = await API.get(
      `${markets.getAllBatches}?state=${state}&page_size=${pageSize}&page_number=${page}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getBatchesCount = async () => {
  try {
    const response = await API.get(markets.getBatchesCount);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const createBeforeMeltingBatch = async (data: BeforeMeltingRecord) => {
  try {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('date', dayjs(data.date).toDate().toISOString());
    formData.append('weight', data.weight);
    formData.append('image', data.image ?? '');
    const response = await API.post(markets.createBeforeMeltingBatch, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateBeforeMeltingBatch = async (data: BeforeMeltingRecord) => {
  try {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('date', dayjs(data.date).toDate().toISOString());
    formData.append('weight', data.weight);
    formData.append('image', data.image ?? '');
    // formData.append('name', data.name);
    const response = await API.patch(`${markets.updateBeforeMeltingBatch}/${data.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const createAfterMeltingBatch = async (data: AfterMeltingRecord) => {
  try {
    const formData = new FormData();
    formData.append('date', dayjs(data.date).toDate().toISOString());
    formData.append('weight', data.weight);
    formData.append('weight_image', data.weight_image ?? '');
    formData.append('purity', data.purity);
    formData.append('purity_image', data.purity_image ?? '');
    formData.append('name', data.name);
    const response = await API.post(`${markets.createAfterMeltingBatch}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateAfterMeltingBatch = async (data: AfterMeltingRecord) => {
  try {
    const formData = new FormData();
    formData.append('date', dayjs(data.date).toDate().toISOString());
    formData.append('weight', data.weight);
    formData.append('weight_image', data.weight_image ?? '');
    formData.append('purity', data.purity);
    formData.append('purity_image', data.purity_image ?? '');
    formData.append('name', data.name);
    const response = await API.patch(
      `${markets.updateAfterMeltingBatch}/${data.id}/after_melting`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const deleteBatch = async (id: string) => {
  try {
    const response = await API.delete(`${markets.deleteAfterMeltingBatch}/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const createMarketBatch = async (data: AfterMeltingRecord) => {
  try {
    const formData = new FormData();
    formData.append('date', dayjs(data.date).toDate().toISOString());
    formData.append('weight', data.weight);
    formData.append('weight_image', data.weight_image ?? '');
    formData.append('purity', data.purity);
    formData.append('purity_image', data.purity_image ?? '');
    formData.append('name', data.name);
    const response = await API.post(`${markets.createMarketBatch}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateMarketBatch = async (data: AfterMeltingRecord) => {
  try {
    const formData = new FormData();
    formData.append('date', dayjs(data.date).toDate().toISOString());
    formData.append('weight', data.weight);
    formData.append('weight_image', data.weight_image ?? '');
    formData.append('purity', data.purity);
    formData.append('purity_image', data.purity_image ?? '');
    formData.append('name', data.name);

    const response = await API.patch(`${markets.updateMarketBatch}/${data.id}/market`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const createBidderSalesBatch = async (data: BidderSaleRecord) => {
  try {
    const formData = new FormData();
    formData.append('date', dayjs(data.date).toDate().toISOString());
    formData.append('weight', data.weight);
    formData.append('purity', data.purity);
    if(data.bidder_slip){
      formData.append('bidder_slip', data.bidder_slip ?? '');
    }
    formData.append('bid_id', data.bid_id ?? '');
    formData.append('name', data.name);
    const response = await API.post(`${markets.createBidderSales}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateBidderSalesBatch = async (data: BidderSaleRecord) => {
  try {
    const formData = new FormData();
    formData.append('date', dayjs(data.date).toDate().toISOString());
    formData.append('weight', data.weight);
    formData.append('purity', data.purity);
    formData.append('bidder_slip', data.bidder_slip ?? '');
    formData.append('bid_id', data.bid_id ?? '');
    formData.append('name', data.name);
    const response = await API.patch(
      `${markets.updateBidderSales}/${data.id}/bidder_sale`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
