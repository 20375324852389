import toast from 'react-hot-toast';
// store
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Button } from '@mui/material';

import { restrictToTwoDecimals } from 'src/utils/purity-calculation';

// API's
import { getbidder } from 'src/apis/bidder';
import { AppDispatch } from 'src/store/store';
import { createBid, updateBid } from 'src/store/slices/bid-slice';
// components
import { BidData, BidderOption, BidModalProps } from 'src/interfaces';

// custom imports
import CustomModal from 'src/components/modal';
import RHFDatePicker from 'src/components/date-picker';
import { RHFTextField } from 'src/components/hook-form';
import ReactAsyncSelect from 'src/components/async-select';

import { BidderSchema } from '../../schema/bidder-schema';

// Main component
const BidModal: React.FC<BidModalProps> = ({ open, onClose, rowDetails, context, onSave }) => {
    const dispatch = useDispatch<AppDispatch>();
    const methods = useForm({
        resolver: yupResolver(BidderSchema),
        defaultValues: {
            bidder_id: { label: '', value: '' },
            market_rate: undefined,
            weight: undefined,
            bid_date: undefined,
        },
    });

    const { handleSubmit, setValue, reset, formState: { errors, isSubmitting } } = methods;
    const [bidderOptions, setBidderOptions] = useState<BidderOption[]>([]);

    // Fetch bidders from the API
    const fetchBidders = async () => {
        try {
            const response = await getbidder();
            setBidderOptions(
                response.map((bidder: any) => ({
                    label: bidder.label,
                    value: bidder.value,
                }))
            );
        } catch (error) {
            toast.error('Failed to fetch bidders:', error);
        }
    };

    // UseEffect to fetch bidder options and set default values when clicked on edit
    useEffect(() => {
        if (open) {
            fetchBidders();

            if (context === 'edit' && rowDetails) {
                setValue('bidder_id', { label: rowDetails.bidder?.name, value: rowDetails.bidder?.id });
                setValue('market_rate', rowDetails.market_rate);
                setValue('weight', rowDetails.weight);
                setValue('bid_date', rowDetails.bid_date);
            } else {
                reset();
            }
        }
    }, [open, rowDetails, context, setValue, reset]);

    // Create bid handler
    const createBidHandler = async (formData: BidData) => {
        try {
            const newBid = await dispatch(createBid(formData)).unwrap();
            toast.success('Bid Created Successfully');
            if (onSave) {
                onSave(newBid);
            }
            onClose();
        } catch (error) {
            toast.error('Error creating bid');
        }
    };

    // Update bid handler
    const updateBidHandler = async (formData: BidData) => {
        try {
            const updatedBid = await dispatch(updateBid({ bidId: rowDetails?.id, formData })).unwrap();
            toast.success('Bid Updated Successfully');
            if (onSave) {
                onSave(updatedBid);
            }
            onClose();
        } catch (error) {
            toast.error('Error updating bid');
        }
    };

    // Set today's date as the minimum date
    const today = new Date();
    today.setDate(today.getDate());
    today.setHours(0, 0, 0, 0);

    return (
        <FormProvider {...methods}>
            <CustomModal
                open={open}
                onClose={onClose}
                title={context === 'add' ? 'Add New Bid Details' : 'Update Bid Details'}
                onSubmit={handleSubmit(context === 'add' ? createBidHandler : updateBidHandler)}
                buttons={
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={onClose}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={isSubmitting}
                        >
                            {context === 'add' ? 'Add Bid' : 'Update Bid'}
                        </LoadingButton>
                    </Stack>
                }
            >
                <Grid container spacing={4} direction="column" mb={2} pt={4}>
                    <Grid item md={6} xs={12}>
                        <RHFDatePicker
                            name="bid_date"
                            label="Bid Date *"
                            fullWidth
                            minDate={today}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ReactAsyncSelect
                            name="bidder_id"
                            label="Bidder *"
                            defaultOptions={bidderOptions}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RHFTextField
                            name="market_rate"
                            label="Gold rate per gram"
                            type="number"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ onInput: restrictToTwoDecimals }}
                            required
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <RHFTextField
                            name="weight"
                            label="Bid Weight in gms"
                            type="number"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ onInput: restrictToTwoDecimals }}
                            required
                        />
                    </Grid>
                </Grid>
            </CustomModal>
        </FormProvider>
    );
};

export default BidModal;

