import React, { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const defaultSx = {
  backgroundColor: '#F4F6F8',
};

const initialColumnPinning = {
  left: ['s_no', 'ornaments', 'ornament_images'],
  right: ['xml', 'action'],
};

interface MaterialTableProps {
  fields: any[];
  columns: any;
}

export const MaterialTable: React.FC<MaterialTableProps> = ({ fields, columns }) => {
  // Memoize columns and fields to prevent unnecessary re-renders
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => fields, [fields]);

  const table = useMaterialReactTable({
    columns: memoizedColumns,
    data: memoizedData,
    layoutMode: 'grid-no-grow',
    enableColumnPinning: true,
    enablePagination: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableStickyHeader: true,
    enableSorting: false,
    enableFilters: false,
    enableColumnFilters: false,
    enableTopToolbar: false,
    muiTableHeadCellProps: { sx: defaultSx },
    muiTableFooterCellProps: { sx: defaultSx },
    initialState: { columnPinning: initialColumnPinning },
  });

  return (
    <MaterialReactTable table={table} />
  );
};
