import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Styles
import { makeStyles } from '@material-ui/styles';
import { useState, useEffect, SetStateAction } from 'react';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Components
import { Box, Modal, Button, IconButton, Typography } from '@mui/material';

import { ImageCarouselProps } from 'src/interfaces';
import { deleteImage, updateImage } from 'src/apis/estimations';



const ImageCarousel: React.FC<ImageCarouselProps> = ({ open, handleClose, images, setImages }) => {
  const classes = useStyles();

  // State for zoom level, rotation, current image index, etc.
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  useEffect(() => {
    setImageLoading(true);
  }, [currentIndex, zoom, rotation]);

  const handleZoomIn = () => setZoom((prev) => Math.min(prev + 0.2, 3));
  const handleZoomOut = () => setZoom((prev) => Math.max(prev - 0.2, 1));
  const handleRotate = () => setRotation((prev) => prev + 90);

  const handleDelete = async () => {
    const currentFile = images[currentIndex];
    if (typeof currentFile === "object" && "id" in currentFile && currentFile.id) {
      const fileId = currentFile.id;
  
      try {
        await deleteImage([fileId]); 
      } catch (error) {
        console.error("Error deleting image:", error);
        return;
      }
    }
  
    // Remove from UI state
    setImages((prevImages) => {
      const newImages = prevImages.filter((_, index) => index !== currentIndex);
      if (newImages.length === 0) {
        handleModalClose();
      }
      return newImages;
    });
    setCurrentIndex((prevIndex) => {
      if (images.length === 1) return 0;
      if (prevIndex === images.length - 1) return prevIndex - 1;
      return prevIndex;
    });
    setZoom(1);
    setRotation(0);
  };
  

  const handleMainImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
  
    if (!file) return;
  
    const currentFile = images[currentIndex];
    const fileId = currentFile.id;
    if (typeof currentFile === "object" && "id" in currentFile && currentFile.id) {
      // If it's an API image (ID exists), update via PATCH API
      try {
        await updateImage(fileId, file); 
      } catch (error) {
        console.error("Error updating image:", error);
        return;
      }
    }
  
    // Update UI state
    setImages((prev) => prev.map((img, index) => (index === currentIndex ? file : img)));
  
    setZoom(1);
    setRotation(0);
  };
  

  const handleNext = () => setCurrentIndex((prev) => (prev + 1) % images.length);
  const handlePrev = () => setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  const handleThumbnailClick = (index: SetStateAction<number>) => {
    setCurrentIndex(index);
    setZoom(1);
    setRotation(0);
  };

  const handleModalClose = () => {
    setZoom(1);
    setRotation(0);
    handleClose();
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImages((prev) => [...prev, file]);
    }
  };

  const isFileSupportedForPreview = (file: string | File | any) => {
    if (file instanceof File) {
      return file.type.startsWith('image/');
    } if (typeof file === 'string') {
      // Check URL or file path for supported image extensions
      return /\.(jpg|jpeg|png|gif|webp|svg)$/i.test(file.split('?')[0]);
    } if (typeof file === 'object' && file !== null && file.name) {
      // Handle API objects with a `name` field
      return /\.(jpg|jpeg|png|gif|webp|svg)$/i.test(file.name.split('?')[0]);
    }
    return false;
  };
  // Helper function to compute an image URL similar to our Dropzone
  const getImageSrc = (file: any): string => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    if (typeof file === 'string') {
      return file;
    }
    if (file && typeof file === 'object' && file.name && typeof file.name === 'string') {
      return file.name;
    }
    return '';
  };

  const getThumbnailWidth = (imageCount: number): string => {
    if (imageCount <= 1) return '12%';
    if (imageCount <= 2) return '15%';
    if (imageCount <= 3) return '20%';
    if (imageCount <= 4) return '25%';
    return '30%';
  };

  const renderMainContent = () => {
    const currentFile = images[currentIndex];
    const isPreviewSupported = isFileSupportedForPreview(currentFile);
    const computedUrl = getImageSrc(currentFile);

    if (isPreviewSupported) {
      return (
        <img
          src={computedUrl}
          alt={`main-${currentIndex}`}
          className={classes.mainImage}
          style={{ transform: `scale(${zoom}) rotate(${rotation}deg)` }}
        />
      );
    }

    return (
      <Box className={classes.noPreviewContainer}>
        <Typography variant="h5">No preview available</Typography>
        <Button
          onClick={() =>
            window.open(
              computedUrl,
              '_blank'
            )
          }
          variant="primary-outline"
          className={classes.downloadButton}
          startIcon={<SaveAltIcon />}
        >
          Download
        </Button>
        <Typography variant="body2" color="white" mt={1}>
          Preview is supported only for images. Download the file to view its content.
        </Typography>
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box className={classes.modalContainer}>
        <Box className={classes.imageContainer}>
          <Box className={classes.iconContainer}>
            <IconButton onClick={handleRotate} className={classes.iconButton}>
              <RotateRightIcon />
            </IconButton>
            <IconButton onClick={handleDelete} className={classes.iconButton}>
              <DeleteIcon />
            </IconButton>
            <IconButton component="label" className={classes.iconButton}>
              <CloudUploadIcon />
              <input
                type="file"
                accept="image/*"
                onChange={handleMainImageUpload}
                hidden
              />
            </IconButton>
            <IconButton onClick={handleZoomIn} className={classes.iconButton}>
              <ZoomInIcon />
            </IconButton>
            <IconButton onClick={handleZoomOut} className={classes.iconButton}>
              <ZoomOutIcon />
            </IconButton>
            <Box className={classes.counterText}>
              {currentIndex + 1}/{images.length}
            </Box>
            <IconButton onClick={handleModalClose} className={classes.iconButton}>
              <CloseIcon />
            </IconButton>
          </Box>
          <IconButton onClick={handlePrev} className={classes.navButtonLeft}>
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton onClick={handleNext} className={classes.navButtonRight}>
            <ArrowForwardIosIcon />
          </IconButton>
          <Box className={classes.zoomContainer}>{renderMainContent()}</Box>
        </Box>

        {/* Thumbnail Carousel */}
        <Box
          className={classes.thumbnailContainer}
          style={{ width: getThumbnailWidth(images.length) }}
        >
          <Slider
            dots={false}
            infinite={false}
            speed={10}
            slidesToShow={Math.min(images.length + 1, 6)}
            slidesToScroll={1}
            arrows={false}
          >
            {images.map((img, index) => {
              const thumbUrl = getImageSrc(img);
              return (
                <Box
                  key={index}
                  className={classes.thumbnailBox}
                  onClick={() => handleThumbnailClick(index)}
                >
                  {isFileSupportedForPreview(img) ? (
                    <img
                      src={thumbUrl}
                      alt={`thumbnail-${index}`}
                      className={`${classes.thumbnailImage} ${
                        currentIndex === index ? classes.selectedThumbnail : ''
                      }`}
                    />
                  ) : (
                    <Box className={classes.noPreviewThumbnail}>
                      <Typography variant="caption">No Preview</Typography>
                    </Box>
                  )}
                </Box>
              );
            })}
            <Box className={classes.thumbnailBox}>
              <Box className={classes.addThumbnailBox}>
                <label htmlFor="file-upload" className={classes.addLabel}>
                  +
                  <input id="file-upload" type="file" accept="image/*, .pdf, .docx" onChange={handleFileUpload} className={classes.fileInput} />
                </label>
              </Box>
            </Box>
          </Slider>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageCarousel;

const useStyles = makeStyles({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flexDirection: 'column',
    position: 'relative',
  },
  imageContainer: {
    width: '80%',
    height: '60%',
  },
  zoomContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  mainImage: {
    maxWidth: '80%',
    maxHeight: '80%',
    objectFit: 'contain',
    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
    transformOrigin: 'center center',
  },
  noPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    height: '80%',
    backgroundColor: 'transparent',
    borderRadius: '10px',
    textAlign: 'center',
    color: 'white',
  },
  downloadButton: {
    marginTop: '10px',
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  iconContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    zIndex: 10,
  },
  iconButton: {
    color: 'white',
  },
  counterText: {
    fontSize: '14px',
    color: 'white',
  },
  navButtonLeft: {
    position: 'absolute',
    left: 60,
    top: '45%',
    transform: 'translateY(-50%)',
    color: 'white',
  },
  navButtonRight: {
    position: 'absolute',
    right: 60,
    top: '45%',
    transform: 'translateY(-50%)',
    color: 'white',
  },
  thumbnailContainer: {
    alignItems: 'center',
    marginTop: 20,
  },
  thumbnailImage: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    cursor: 'pointer',
    transition: 'border 0.3s ease',
    borderRadius: '10px',
  },
  selectedThumbnail: {
    border: '2px solid white',
  },
  noPreviewThumbnail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
    backgroundColor: 'rgb(180, 180, 180)',
    borderRadius: '10px',
    color: 'white',
    fontSize: '8px',
  },
  thumbnailBox: {
    width: '80px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  addThumbnailBox: {
    width: '80px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(180, 180, 180)',
    borderRadius: '10px',
    cursor: 'pointer',
    border: '2px solid white',
    textAlign: 'center',
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  addLabel: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  fileInput: {
    display: 'none',
  },
});
